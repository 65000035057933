import React, {useState} from "react";
import {useDbContext} from "../contexts/dbContext";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PreviewIcon from '@mui/icons-material/Preview';
import Button from "@mui/material/Button";
import {Checkbox, Input, Switch} from "@mui/material";
import {useAppContext} from "../contexts/appContext";
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import AddIcon from '@mui/icons-material/Add';
import {bordercolor} from "../theme";

export default function HelpWindow(props){
    const {hideMenu} = props;
    const {decks, getdecks, starterScreen, setStarterScreen} = useDbContext();
    const [searchText, setSearchText] = useState("");
    const [showOwn, setShowOwn] = useState(false);
    const [showSameName, setShowSameName] = useState(false);

    return (
        <div
            className={bordercolor + " border absolute flex flex-col h-[80%] lg:h-[80%] w-full lg:w-[70%] lg:left-[15%] bg-[#091C1EFF] rounded-2xl overflow-hidden " + (hideMenu ? "top-[9%]" : "top-[15%]")}>
            <div className={"flex flex-row justify-between items-center h-14 w-full"}>
                <div>

                </div>
                <p className={"flex flex-row h-full justify-center items-center text-lg"}>Help Guide</p>
                <Button onClick={() => setStarterScreen(false)}>
                    X
                </Button>
            </div>
            <div className={"flex flex-row h-full w-full overflow-y-hidden overflow-x-auto"}>

            </div>
            <div className={"flex flex-row h-14 w-full"}>

            </div>
        </div>
    )
}

const sdeckstyle = "flex flex-col h-full w-[500px] bg-header items-center justify-start rounded-2xl px-4";
const sdeckbutton = "flex h-14 w-full justify-center items-center bg-secondary";

function NewPlayerWindow() {
    const hideMenu = true;
    return (
        <div
            className={"absolute flex flex-col lg:px-6 h-[80%] lg:h-[80%] w-full lg:w-[70%] lg:left-[15%] bg-footer rounded-2xl overflow-hidden " + (hideMenu ? "top-[9%]" : "top-[15%]")}>
            <div className={"flex flex-row h-14 w-full justify-center items-center text-primary"}>
                <p>Welcome new player discover some starter decks below.</p>
            </div>
            <div className={"flex flex-row h-full w-full overflow-y-hidden overflow-x-auto pb-8"}>
                <div className={"flex flex-row h-full w-auto gap-2 justify-center items-center"}>
                    <div className={sdeckstyle}>
                        <p className={"flex flex-row h-14 w-full text-center justify-center items-center text-lg font-bold"}>Starter
                            Deck A</p>
                        <div className={"flex flex-row w-full h-[70%] justify-center items-center"}>
                            <div
                                className={"flex flex-col w-20 h-full justify-between items-center pt-4 gap-2 text-xs"}>
                                <div
                                    className={"flex flex-col w-20 h-auto justify-start items-center pt-4 gap-2 text-xs"}>
                                    <div className={sdeckbutton}>Units(0)</div>
                                    <div className={sdeckbutton}>Actions(0)</div>
                                    <div className={sdeckbutton}>Mana(0)</div>
                                </div>

                                <div className={sdeckbutton + " justify-self-end self-end"}>
                                    Add Deck
                                </div>
                            </div>

                            <div className={"h-full w-full overflow-x-hidden overflow-y-auto"}>
                                <div className={"flex flex-wrap justify-center items-center w-full h-auto gap-2"}>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={"flex flex-wrap h-auto min-h-20 w-full text-center justify-center items-center"}>
                            This is a starter deck description sample
                        </p>
                    </div>

                    <div className={sdeckstyle}>
                        <p className={"flex flex-row h-14 w-full text-center justify-center items-center text-lg font-bold"}>Starter
                            Deck A</p>
                        <div className={"flex flex-row w-full h-[70%] justify-center items-center"}>
                            <div
                                className={"flex flex-col w-20 h-full justify-between items-center pt-4 gap-2 text-xs"}>
                                <div
                                    className={"flex flex-col w-20 h-auto justify-start items-center pt-4 gap-2 text-xs"}>
                                    <div className={sdeckbutton}>Units(0)</div>
                                    <div className={sdeckbutton}>Actions(0)</div>
                                    <div className={sdeckbutton}>Mana(0)</div>
                                </div>

                                <div className={sdeckbutton + " justify-self-end self-end"}>
                                    Add Deck
                                </div>
                            </div>

                            <div className={"h-full w-full overflow-x-hidden overflow-y-auto"}>
                                <div className={"flex flex-wrap justify-center items-center w-full h-auto gap-2"}>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={"flex flex-wrap h-auto min-h-20 w-full text-center justify-center items-center"}>
                            This is a starter deck description sample
                        </p>
                    </div>

                    <div className={sdeckstyle}>
                        <p className={"flex flex-row h-14 w-full text-center justify-center items-center text-lg font-bold"}>Starter
                            Deck A</p>
                        <div className={"flex flex-row w-full h-[70%] justify-center items-center"}>
                            <div
                                className={"flex flex-col w-20 h-full justify-between items-center pt-4 gap-2 text-xs"}>
                                <div
                                    className={"flex flex-col w-20 h-auto justify-start items-center pt-4 gap-2 text-xs"}>
                                    <div className={sdeckbutton}>Units(0)</div>
                                    <div className={sdeckbutton}>Actions(0)</div>
                                    <div className={sdeckbutton}>Mana(0)</div>
                                </div>

                                <div className={sdeckbutton + " justify-self-end self-end"}>
                                    Add Deck
                                </div>
                            </div>

                            <div className={"h-full w-full overflow-x-hidden overflow-y-auto"}>
                                <div className={"flex flex-wrap justify-center items-center w-full h-auto gap-2"}>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={"flex flex-wrap h-auto min-h-20 w-full text-center justify-center items-center"}>
                            This is a starter deck description sample
                        </p>
                    </div>
                </div>
            </div>
            <div className={"hidden lg:flex flex-row h-14 w-full"}>

            </div>
        </div>
    )
}