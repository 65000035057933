import React, {useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import HomeIcon from "@mui/icons-material/Home";
import SummarizeIcon from "@mui/icons-material/Summarize";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {Dashboard} from "./dashboard";
import {MatchOverview} from "./matchoverview";
import {OnlinePlayersTable} from "./onlineplayers";
import PeopleIcon from '@mui/icons-material/People';
import {useAppContext} from "../contexts/appContext";
import Axios from "axios";
import {dblink} from "../App";
import toast from "react-hot-toast";
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import CardUpdate from "./cardupdate";
import {AdminContextProvider} from "../contexts/adminContext";
import DecksList from "./deckslist";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

export default function AdminPanel(props) {
    const {isAdmin} = useAppContext();
    const [sbOpen, setSbOpen] = useState(false);
    const [openPage, setOpenPage] = useState("dashboard");
    const [updatingCards, setUpdatingCards] = useState(false);

    async function UpdateCards(){
        setUpdatingCards(true);
        Axios({
            method: "POST",
            url: dblink + "/api/admin/updatecards",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json"
            },
        }).then((res) => {
            console.log(res);
            if(res.data.status === "ok"){
                toast.success("Successfully refreshed card data, cards updated: " + res.data.updatedcards, {position: "bottom-right"});
                if(res.data.images.length > 0){
                    toast.success("Updated " + res.data.images.length + " images.",{position: "bottom-right"});
                    for(let i =0; i< res.data.images.length; i++) {
                        toast.success("Image refreshed for " + res.data.images[i], {position: "bottom-right"});
                    }
                } else toast.success("No new images found.", {position: "bottom-right"});
                setUpdatingCards(false);
            } else {
                toast.error("Failed to update card data..", {position: "bottom-right"});
                setUpdatingCards(false);
            }
        });
    }

    return (
        <AdminContextProvider>
            <div className={"flex flex-row h-full w-full"}>
                <div className={"flex flex-col h-full bg-[#131a20] bg-opacity-60 pt-4 border-r border-[#51b78c] border-opacity-30 " + (sbOpen ? "w-52" : "w-14")}>
                    <div className={"flex flex-col h-full w-full items-center justify-between"}>
                        <SBMenu sbOpen={sbOpen} openPage={openPage} setOpenPage={setOpenPage} />
                        <div className={"flex flex-col gap-4 h-auto w-full justify-center items-center px-2"}>
                            <SBButton sbOpen={sbOpen} setSbOpen={setSbOpen}/>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-row w-full h-full"}>
                    {openPage === "dashboard" && <Dashboard/>}
                    {openPage === "matchreports" && <MatchOverview/>}
                    {openPage === "players" && <OnlinePlayersTable/>}
                    {openPage === "decks" && <DecksList/>}
                    {openPage === "cardsupdate" && <CardUpdate updatingCards={updatingCards} UpdateCards={UpdateCards} />}
                </div>
            </div>
        </AdminContextProvider>
    )
}



function SBMenu(props) {
    const {sbOpen, openPage, setOpenPage} = props;

    return (
        <div className={"flex flex-col h-auto w-full items-center px-2 gap-2"}>
            <Tooltip title="Admin Dashboard" placement="right">
                <div
                    className={(openPage === "dashboard" ? "bg-[#51b78c]" : "bg-[#3b6f5e26]") + (sbOpen ? " w-full " : " w-10 ") + " flex flex-row h-10 border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                    onClick={() => {
                        setOpenPage("dashboard")
                    }}
                >
                    <HomeIcon/>
                    {sbOpen && <p> Dashboard</p>}
                </div>
            </Tooltip>
            <Tooltip title="Players" placement="right">
                <div
                    className={(openPage === "players" ? "bg-[#51b78c]" : "bg-[#3b6f5e26]") + (sbOpen ? " w-full " : " w-10 ") + " flex flex-row h-10 border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                    onClick={() => {
                        setOpenPage("players")
                    }}
                >
                    <PeopleIcon />
                    {sbOpen && <p> Online Players</p>}
                </div>
            </Tooltip>
            <Tooltip title="Match Reports" placement="right">
                <div
                    className={(openPage === "matchreports" ? "bg-[#51b78c]" : "bg-[#3b6f5e26]") + (sbOpen ? " w-full " : " w-10 ") + "  flex flex-row h-10 border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                    onClick={() => {
                        setOpenPage("matchreports")
                    }}
                >
                    <SummarizeIcon/>
                    {sbOpen && <p> Match Reports</p>}
                </div>
            </Tooltip>
            <Tooltip title="Decks" placement="right">
                <div
                    className={(openPage === "decks" ? "bg-[#51b78c]" : "bg-[#3b6f5e26]") + (sbOpen ? " w-full " : " w-10 ") + "  flex flex-row h-10 border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                    onClick={() => {
                        setOpenPage("decks")
                    }}
                >
                    <ViewModuleIcon fontSize={"large"}/>
                    {sbOpen && <p> Decks</p>}
                </div>
            </Tooltip>
            <Tooltip title="Cards Update" placement="right">
                <div
                    className={(openPage === "cardsupdate" ? "bg-[#51b78c]" : "bg-[#3b6f5e26]") + (sbOpen ? " w-full " : " w-10 ") + " flex flex-row h-10 border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                    onClick={() => {
                        setOpenPage("cardsupdate")
                    }}
                >
                    <SystemUpdateIcon />
                    {sbOpen && <p> Update Cards</p>}
                </div>
            </Tooltip>
        </div>
    )
}

function SBButton(props) {
    const {sbOpen, setSbOpen} = props;
    return (
        <div className={"hidden lg:flex flex-col h-auto w-full items-center pb-4"}>
            <Tooltip title="Expand Sidebar" placement="right">
                <div onClick={() => {
                    setSbOpen(!sbOpen);
                }}
                     className={"flex flex-row w-10 h-10 bg-[#3b6f5e26] border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center mx-auto"}>
                    {sbOpen ? <KeyboardDoubleArrowLeftIcon/> : <KeyboardDoubleArrowRightIcon/>}
                </div>
            </Tooltip>
        </div>
    )
}