import React, {useEffect, useState} from "react";
import {bordercolor} from "../theme";
import Pagination from "@mui/material/Pagination";
import {Checkbox, PaginationItem, ToggleButton, ToggleButtonGroup} from "@mui/material";
import DbNavBar from "./dbnavbar";
import {useAppContext} from "../contexts/appContext";
import {useDbContext} from "../contexts/dbContext";
import HelpWindow from "./HelpWindow";
import CardBox from "./cardbox";
import RightBar from "./rightbar";
import Button from "@mui/material/Button";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import Slider from "@mui/material/Slider";
import PublicDecks from "./publicDecks";
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import Tooltip from "@mui/material/Tooltip";

export function DeckBuilder(props) {
    const {id, hideMenu, setHideMenu, cardsOnly, openPage, username, session} = useAppContext();
    const {
        starterScreen,
        setStarterScreen,
        currentPage,
        displayCards,
        pages,
        setSearchText,
        keywordFilter,
        setKeywordFilter,
        DisplayMode,
        swapDisplayMode,
        setCurrentPage,
        TotalCards,
        InDeck,
        SetInDeck,
        cardSize,
        setCardSize,
        cardsPerPage,
        setCardsPerPage,
        currentPageLabel,
        setCurrentPageLabel,
        deckWindow,
        setDeckWindow,
        decksLoading
    } = useDbContext();

    //#region States
    const { hoverId, setHoverID, setHoverType} = props;
    const [excludeAnyDeck, setExcludeAnyDeck] = useState(false);
    const [decklistOpen, setDeckListOpen] = useState(false);
    const [openTab, setOpenTab] = useState("DeckList");
    const [openADTab, setOpenADTab] = useState("info");
    const [dsOpen, setDsOpen] = useState(false);
    const [dpOpen, setDpOpen] = useState(false);
    const [st, setSt] = useState("");
    //#endregion

    //#region Change handlers
    const handleDisplayChange = (
        event,
        newAlignment,
    ) => {
        swapDisplayMode(newAlignment);
    };

    const handleKWChange = (event) => {
        const {
            target: {value},
        } = event;
        setKeywordFilter(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleDeckChange =(
        event,
        newAlignment,
    ) => {
        SetInDeck(newAlignment);
    };

    function startsearch() {
        setSearchText(st);
    }

    if(decksLoading){
        <>Loading Decks..</>
    } else
    return (
        <div className={"flex flex-col h-full w-full overflow-y-hidden"}>
            {decksLoading ?
                <>Loading Decks..</> :
                <div className={"flex flex-col h-full w-full overflow-y-hidden"}>
                    <DbNavBar startsearch={startsearch} dsOpen={dsOpen} setDsOpen={setDsOpen} setSt={setSt} st={st}
                              handleDisplayChange={handleDisplayChange}
                              handleKWChange={handleKWChange} dpOpen={dpOpen} setDpOpen={setDpOpen}/>

                    {dpOpen ?
                        <div className={"flex flex-row w-full h-full overflow-y-hidden"}>
                            <div className={"flex flex-col w-full h-full overflow-y-hidden"}>
                                <CardBox/>
                            </div>
                            <RightBar setHoverType={setHoverType} setHoverID={setHoverID}/>
                        </div>
                        :
                        <div className={"flex flex-row w-full h-full overflow-y-hidden"}>
                            <CardBox/>
                        </div>}
                    <div className={"flex flex-row w-full h-14 justify-center bg-[#01130FF7] bg-opacity-40 items-center px-6 gap-1 border-t overflow-y-hidden " + bordercolor}>
                        <div className={"flex flex-row justify-between text-[#51b78c] items-center w-1/3 px-4 h-full text-sm"}>
                            <div className={"flex flex-row w-auto h-full justify-center items-center"}>
                                <Tooltip title={"Open help compendium"} placement="top">
                                    <Button color={starterScreen ? "primary" : "secondary"}
                                            className={"flex flex-row w-12 h-12 justify-center items-center "}
                                            onClick={() => {
                                                setStarterScreen(!starterScreen);
                                            }}>
                                        <HelpCenterIcon fontSize={"large"}/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title={"Open public decks"} placement="top">
                                    <Button color={deckWindow ? "primary" : "secondary"}
                                            className={"flex flex-row w-12 h-12 justify-center items-center "}
                                            onClick={() => {
                                                setDeckWindow(!deckWindow);
                                            }}>
                                        <ViewQuiltIcon fontSize={"large"}/>
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className={"flex flex-row w-auto h-full justify-center items-center"}>
                                <ToggleButtonGroup
                                    color="primary"
                                    className={"bg-[#244939] h-10"}
                                    value={InDeck}
                                    exclusive
                                    onChange={handleDeckChange}
                                    aria-label="DisplayMode">
                                    <ToggleButton value="all">All</ToggleButton>
                                    <ToggleButton value="deck">In Deck</ToggleButton>
                                    <ToggleButton value="notindeck">Not In Deck</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <p className={"flex flex-row w-[150px] h-full justify-center items-center"}>{TotalCards() + " cards"}</p>
                        </div>
                        <div className={"flex flex-row h-full w-1/3  gap-1 items-center justify-center text-white"}>
                            <Pagination page={currentPageLabel} color={"primary"} onChange={(e, p) => {
                                setCurrentPageLabel(p);
                            }} count={pages.length} variant={"outlined"} shape="rounded" renderItem={(item) => (
                                <PaginationItem sx={{color: 'secondary.main'}}
                                                {...item}
                                />
                            )}/>
                        </div>
                        <div
                            className={"flex flex-row w-1/3  h-full justify-start items-center text-[#51b78c] text-sm"}>
                            <div className={"flex flex-row w-full h-full gap-2 justify-center text-xs items-center"}>
                                <div className={"flex flex-row items-center justify-center text-xs gap-1 h-14 w-1/2"}>
                                    <p className={"flex flex-row  w-[100px] justify-center items-center"}>Card Size</p>
                                    <Slider
                                        size="small"
                                        min={0.00}
                                        max={1.00}
                                        step={0.01}
                                        defaultValue={cardSize}
                                        value={cardSize}
                                        onChange={(e, v) => {
                                            setCardSize(v);
                                            localStorage.setItem("cardsize", v.toString());
                                        }
                                        }
                                        aria-label="Small"
                                        valueLabelDisplay="auto">
                                    </Slider>
                                    <input placeholder={"width"} value={cardSize}
                                           onChange={(e) => {
                                               if (e.target.value === "") {
                                                   setCardSize(0.01);
                                               } else setCardSize(parseFloat(e.target.value));
                                           }}
                                           className={"h-8 bg-[#244939] border w-10 border-[#51b78c] rounded-lg p-1"}/>
                                </div>
                                <div className={"flex flex-row items-center justify-center h-14 gap-1 w-1/2"}>
                                    <p className={"flex flex-row w-[100px] justify-center items-center"}>Cards</p>
                                    <Slider
                                        size="small"
                                        min={1}
                                        max={500}
                                        step={1}
                                        defaultValue={cardsPerPage}
                                        value={cardsPerPage}
                                        onChange={(e, v) => {
                                            setCardsPerPage(v);
                                            localStorage.setItem("cardspage", v.toString());
                                        }}
                                        aria-label="Small"
                                        valueLabelDisplay="auto">
                                    </Slider>
                                    <input placeholder={"width"} value={cardsPerPage}
                                           onChange={(e) => {
                                               if (e.target.value === "") {
                                                   setCardsPerPage(1);
                                               } else setCardsPerPage(parseInt(e.target.value));
                                           }}
                                           className={"h-8 bg-[#244939] border w-10 border-[#51b78c] rounded-lg p-1"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {starterScreen && <HelpWindow hideMenu={hideMenu}/>}
                    {deckWindow && <PublicDecks setHoverType={setHoverType} fullpage={false} setHoverID={setHoverID} hoverId={hoverId} hideMenu={hideMenu}/>}
                </div>}
        </div>
    )
}
const sdeckstyle = "flex flex-col h-full w-[500px] items-center justify-start";