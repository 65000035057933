import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const bgcolor = "bg-slate-600 text-[#b9f3e0]";

export default function ConfirmDialog(props) {
    const {deckEntry, DeleteDeck} = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAccept =() => {
        DeleteDeck(deckEntry);
        setOpen(false);
    }

    return (
        <>
            <div className={"flex flex-row h-full w-auto justify-center items-center"}>
                <Button tabIndex={2} variant="outlined" className={"flex flex-row h-10"} onClick={handleClickOpen}>
                    <DeleteForeverIcon/>
                </Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={"bg-slate-800 bg-opacity-60 text-[#b9f3e0]"}
            >
                <DialogTitle className={bgcolor} id="alert-dialog-title">
                    {"Deck Deletion"}
                </DialogTitle>
                <DialogContent className={bgcolor}>
                    <DialogContentText id="alert-dialog-description" className={bgcolor + " flex-col"}>
                        <p>Are you sure you want to delete the deck: </p>
                        {deckEntry.id !== "" && deckEntry.deckName}
                        This cannot be reversed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={bgcolor}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAccept} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}