export const dbstyle = {
    headercolors: " bg-[#262011] border-[#716039]",
    boxcolors: " bg-[#43391e] border-[#716039]",

    headertop: " flex flex-row w-full h-14 rounded-t-2xl justify-center items-center text-white text-xl justify-center",
    headermiddle: "flex flex-row w-full h-12 border border-slate-700 justify-center items-center ",
    headerbottom: "flex flex-row w-full h-12 rounded-b-2xl justify-center items-center",

    cardcontainerborder: " border border-slate-500",
    pagingstyle: "flex flex-col mx-1 text-slate-100 hover:opacity-75",
    pagingstyleSelected: "flex flex-col mx-1 text-blue-500 hover:opacity-75",
    pagingbutton: "flex flex-row w-8 h-8 ml-4 mr-4 p-2 text-sm bg-[#262011] border-2 hover:border-3 border-[#716039] hover:opacity-70",

    cards: {
        selected: "flex flex-col overflow-hidden rounded-xl items-center justify-items-center border-4 border-[#51b78c] hover:opacity-70",
        notselected: "flex flex-col rounded-lg border-2 overflow-hidden border-[#51b78c] border-opacity-50 items-center justify-items-center hover:opacity-70"
    },

    opentab: "flex flex-row w-full mx-2 my-1 text-lg h-10 rounded px-4 justify-center bg-[#262011] items-center hover:bg-slate-600 border border-[#716039]",
    closedtab: "flex flex-row w-full h-10 mx-2 my-1 text-lg justify-center items-center hover:bg-slate-500 bg-[#262011] border border-[#716039]",

    button: "text-xl justify-center items-center hover:bg-slate-500 bg-[#262011] border border-[#716039]",
    filterbox: " bg-[#262011]  ",
    searchbox: " bg-[#262011] ",
    deckbox: " bg-[#262011]  ",
}
