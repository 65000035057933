import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SortIcon from '@mui/icons-material/Sort';
import {useDbContext} from "../contexts/dbContext";

export default function SortMenu(props) {
    const {setSortType, DisplayMode} = useDbContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <SortIcon /><p className={"hidden lg:flex uppercase"}>Sort</p>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    setSortType("CostAsc");
                    handleClose();}}>Total Cost (asc)</MenuItem>
                <MenuItem onClick={() => {
                    setSortType("CostDesc");
                    handleClose();}}>Total Cost (desc)</MenuItem>
                <MenuItem onClick={() => {
                    setSortType("NameAsc");
                    handleClose();}}
                >Name (asc)</MenuItem>
                <MenuItem onClick={() => {
                    setSortType("NameDesc");
                    handleClose();}}>Name (desc)</MenuItem>
                <MenuItem onClick={() => {
                    setSortType("IdentityAsc");
                    handleClose();}}>Identity (asc)</MenuItem>
                <MenuItem onClick={() => {
                    setSortType("IdentityDesc");
                    handleClose();}}>Identity (desc)</MenuItem>
                {(DisplayMode === "All" || DisplayMode === "Units")  && <MenuItem onClick={() => {
                    setSortType("AttackAsc");
                    handleClose();}}>Attack (asc)</MenuItem>}
                {(DisplayMode === "All" || DisplayMode === "Units")  && <MenuItem onClick={() => {
                    setSortType("AttackDesc");
                    handleClose();}}>Attack (desc)</MenuItem>}
                {(DisplayMode === "All" || DisplayMode === "Units")  && <MenuItem onClick={() => {
                    setSortType("DefenseAsc");
                    handleClose();}}>Defense (asc)</MenuItem>}
                {(DisplayMode === "All" || DisplayMode === "Units")  && <MenuItem onClick={() => {
                    setSortType("DefenseDesc");
                    handleClose();}}>Defense (desc)</MenuItem>}
            </Menu>
        </div>
    );
}