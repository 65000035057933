import React, {useEffect, useState} from "react";
import {useAppContext} from "../contexts/appContext";
import {Link} from "react-router-dom";
import {bordercolor} from "../theme";
import Button from "@mui/material/Button";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {useStatsContext} from "../contexts/statisticsContext";

export default function SocialDash(props) {
    const {profile, openPage, discord, CheckDiscord, isAdmin} = useAppContext();

   async function LinkDiscord(){
        if(discord) return;
        const { data, error } = await supabase.auth.linkIdentity({ provider: 'discord' })
        if(error){

        } else {
            CheckDiscord();
        }
    }

    async function UnlinkDiscord(){
       if(!discord) return;
        const {
            data: { identities },
        } = await supabase.auth.getUserIdentities()

        const discordid = identities.find((identity) => identity.provider === 'discord')
        const { data, error } = await supabase.auth.unlinkIdentity(discordid)
    }

    const [text, setText] = useState("")
    return (
        <div className={"flex flex-col h-full w-full overflow-hidden p-4"}>
            <div className={"flex flex-col items-center h-full w-full"}>
                <div className={"flex flex-col h-1/2 items-center w-full gap-10"}>
                    <div className={"flex flex-col w-[70%] h-16"}>
                        <UserName/>
                    </div>
                    <div className={"flex flex-col h-auto items-center w-full gap-4"}>
                        <Link
                            className={"flex flex-row h-10 justify-center items-center text-sm text-white w-[70%] bg-[#183326C1] opacity-70 hover:opacity-100 cursor-pointer border " + bordercolor}
                            to={"/statistics"} target={openPage() === "game" ? "_blank" : "_self"}>
                            See statistics
                        </Link>
                        <Link
                            className={"flex flex-row h-10 justify-center items-center text-sm text-white w-[70%] bg-[#183326C1] opacity-70 hover:opacity-100 cursor-pointer border " + bordercolor}
                            to={"/account"} target={openPage() === "game" ? "_blank" : "_self"}>
                            Manage Account
                        </Link>
                        {isAdmin &&
                            <Link
                                className={"flex flex-row h-10 justify-center items-center text-sm text-white w-[70%] bg-[#183326C1] opacity-70 hover:opacity-100 cursor-pointer border " + bordercolor}
                                to={"/admin"} target={openPage() === "game" ? "_blank" : "_self"}>
                                Administration
                            </Link>
                        }
                    </div>
                </div>
                <div
                    className={"flex flex-col w-[70%] h-[150px] justify-start items-start mb-10 border border-[#51b78c]"}>
                    <div
                        className={"flex flex-row w-full h-10 border-b border-[#51b78c] p-2 justify-between items-center"}>
                        Discord
                        <Button size={"small"} className={"btn-ghost"} onClick={() => {
                            UnlinkDiscord();
                        }}>
                            Unlink
                        </Button>
                    </div>
                    {discord ?
                        <div className={"flex flex-row w-full h-full justify-center items-center"}>
                            <img className={"flex flex-row w-20 h-20"} src={discord.identity_data.avatar_url} alt={"avatar"}/>
                            <div className={"flex flex-col w-full h-full justify-center items-center"}>
                                <p>{discord.identity_data.full_name}</p>
                                <p>{discord.identity_data.provider_id}</p>
                            </div>
                        </div>
                        :
                        <Button onClick={() => { LinkDiscord(); }} className={"btn-ghost px-4 py-2"}>
                            Link Discord
                        </Button>
                    }
                </div>
            </div>
            <div className={"flex flex-row h-20 w-full justify-center items-center"}>
                <div
                    className={"flex flex-row h-10 mt-6 justify-center items-center text-sm text-white w-[70%] bg-[#872323C1] opacity-70 hover:opacity-100 cursor-pointer border " + bordercolor}
                    onClick={() => {
                        supabase.auth.signOut();
                        toast.success('Logged out.', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }}>
                    Logout
                </div>
            </div>
        </div>
    )
}

function UserName(props) {
    const {profile, GetProfile, id, email} = useAppContext();
    const [editing, setEditing] = useState(false);
    const [tempName, setTempName] = useState(profile ? profile.username : "");
    const [fieldsComplete, setFieldsComplete] = useState(false);

    useEffect(() => {
        if (tempName.length < 4 || tempName === profile.username) {
            setFieldsComplete(false);
        } else {
            setFieldsComplete(true);
        }
    }, [tempName]);

    async function UpdateAccount(_name, _email) {
        if (_name !== profile.username) {
            const {error} = await supabase.from('profiles')
                .update({username: _name})
                .eq("user_id", id)
            if (error) {
                toast.error('Failed to update username in profile - ' + error.message, {
                    duration: 4000,
                    position: 'bottom-right',
                });
            } else {
                GetProfile(id, _name, _email);
                toast.success("Changes saved successfully.");
            }
        }
    }

    return (
        <div>
            {editing ?
                <div className={"flex flex-row items-center justify-between text-xl py-2 px-2"}>
                    <input
                        className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                        type="text"
                        value={tempName}
                        onChange={(e) => setTempName(e.target.value)}
                    />
                    <div className={"flex flex-col gap-2"}>
                        <Button className={"btn-error"} onClick={() => { setEditing(false); }}>Cancel</Button>
                        <Button disabled={!fieldsComplete} className={"btn-success"} onClick={() => { UpdateAccount(tempName, email); }}>Save</Button>
                    </div>
                </div>
                :
                <div className={"flex flex-row items-center justify-between text-2xl py-4 px-2"}>
                    {profile ? profile.username : ""}
                    <Button className={"btn-ghost"} onClick={() => setEditing(true)}>Change</Button>
                </div>}
        </div>
    )
}