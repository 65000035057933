import {CircularProgress} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, {useEffect, useState} from "react";
import { copy } from "@stianlarsen/copy-to-clipboard";
import toast from "react-hot-toast";

export default function CardUpdate(props) {
    const {updatingCards, UpdateCards} = props;
    const [key, setKey] = useState(0);

    const ClipbackCB = () => {
        toast.success("Password has been copied to the clipboard", {
            duration: 4000,
            position: 'bottom-right',
        });
    };

    useEffect(() => {
        if(key === 10){
            setKey(0);
        } else setKey(key + 1);
    }, [updatingCards]);

    return (
        <div className={"flex flex-col w-full h-full"}>
            <div className={"flex flex-row h-full w-full"}>
                {!updatingCards && <iframe key={key} className={"flex h-full w-full"} src={"https://play.manacaster.com/fb/"}></iframe>}
                {updatingCards && <div className={"flex flex-col justify-center items-center h-full w-full"}>
                    <CircularProgress/>
                    <p> Updating Cards..</p>
                </div>}
            </div>
            <div className={"flex flex-row w-full h-14 border-t border-[#51b78c] justify-end items-center px-10 gap-2"}>
                <div className={"text-sm"}>username: cards</div>
                <div className={"text-green-400 hover:opacity-70"} onClick={() => { copy("N anoclub420", ClipbackCB); }}>pass</div>
                {updatingCards ?
                    <div className={"bg-[#3b6f5e26] opacity-80 w-auto px-2 flex flex-row h-10 border border-[#51b78c] rounded-xl items-center justify-center"}
                    >
                        <p> Updating Cards..</p>
                    </div>
                    :
                    <Tooltip title="Update Cards" placement="right">
                        <div
                            className={"bg-[#3b6f5e26] w-auto px-2 flex flex-row h-10 border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                            onClick={() => {
                                UpdateCards()
                            }}
                        >
                            <RefreshIcon size={"small"} />
                           <p> Update Cards</p>
                        </div>
                    </Tooltip>}
            </div>
        </div>
    )
}