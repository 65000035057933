import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-tooltip/dist/react-tooltip.css'
import { theme } from "./theme";
import {AppContextProvider} from "./contexts/appContext";
import {DbContextProvider} from "./contexts/dbContext";
import {ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {NewsContextProvider} from "./contexts/newsContext";
import {SocialContextProvider} from "./contexts/socialContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <AppContextProvider>
                <DbContextProvider>
                    <NewsContextProvider >
                        <SocialContextProvider>
                            <App />
                            <Toaster />
                        </SocialContextProvider>
                    </NewsContextProvider>
                </DbContextProvider>
            </AppContextProvider>
        </BrowserRouter>
    </ThemeProvider>
);
