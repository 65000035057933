import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {ListItemText} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import LabelIcon from '@mui/icons-material/Label';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ExpandLess} from "@mui/icons-material";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor:
        'rgb(23,31,37)',
    color: '#51b78c',
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        'rgba(255, 255, 255, .05)',
    color: '#51b78c',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    color: '#51b78c',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function PatchNoteDisplay(props) {
    const { art, ind } = props;
    const [folded, setFolded] = useState(ind === 0);
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const cont = JSON.parse(art.content);
    return (
        <div className={"flex flex-col h-auto w-full text-textprimary"}>
            <header className={"flex flex-row h-10 w-full justify-between gap-2"}>
                <div className={"flex flex-row w-auto gap-4 h-full"}>
                    <h2 className="flex flex-wrap h-full w-auto justify-start items-end text-xl font-bold mb-3">{art.title}</h2>
                    <div
                        className={"flex flex-wrap h-full w-auto justify-start items-end text-sm"}>{"Released at " + (cont.rdate && cont.rdate)}</div>
                </div>
            </header>

            <div className={"flex flex-col h-auto w-full justify-start items-start"}>
                <div className={"flex flex-wrap h-auto py-2"}>{cont.description && cont.description}</div>
                <div onClick={() => {
                    setFolded(!folded)
                }}
                     className={"flex flex-row cursor-pointer h-10 w-full justify-start items-center px-4 gap-2 font-semibold select-none " + (folded ? " bg-slate-500 bg-opacity-20 hover:bg-slate-600 hover:bg-opacity-60" : " hover:bg-slate-500 hover:bg-opacity-20")}>
                    <div className={"flex flex-row w-auto h-10 justify-center items-center"}>{folded ? <ExpandLess/> :
                        <ExpandMoreIcon/>}</div>
                    Patch Notes
                </div>
                {folded &&
                    <>
                        <Accordion className={"flex flex-col w-full h-auto text-sm"} expanded={expanded === 'panel1'}
                               onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Known Issues ({cont.issues && cont.issues.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List className={"flex flex-col h-auto w-full justify-start items-center"}>
                                {cont.issues && cont.issues.length > 0 && cont.issues.map((issue, ind) => (
                                    <ListItem key={ind}>
                                        <ListItemIcon>
                                            <LabelIcon/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            {issue}
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={"flex flex-col w-full h-auto"} expanded={expanded === 'panel2'}
                               onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>New Features ({cont.features && cont.features.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cont.features && cont.features.length > 0 && cont.features.map((feature, ind) => (
                                <ListItem key={ind}>
                                    <ListItemIcon>
                                        <LabelIcon/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {feature}
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={"flex flex-col w-full h-auto"} expanded={expanded === 'panel3'}
                               onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <Typography>Improvements ({cont.improvements && cont.improvements.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cont.improvements && cont.improvements.length > 0 && cont.improvements.map((improvement, ind) => (
                                <ListItem key={ind}>
                                    <ListItemIcon>
                                        <LabelIcon/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {improvement}
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={"flex flex-col w-full h-auto"} expanded={expanded === 'panel4'}
                               onChange={handleChange('panel4')}>
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                            <Typography>Fixes ({cont.fixes && cont.fixes.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cont.fixes && cont.fixes.length > 0 && cont.fixes.map((fix, ind) => (
                                <ListItem key={ind}>
                                    <ListItemIcon>
                                        <LabelIcon/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {fix}
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </>}
            </div>
        </div>
    )
}