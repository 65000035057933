import React, {useEffect, useState} from "react";
import {useDbContext} from "../contexts/dbContext";
import {bordercolor} from "../theme";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {ArrowBack, Check, DeleteForever, EditOutlined} from "@mui/icons-material";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ConfirmDialog from "./deleteconfirm";
import {Checkbox} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function RightBar(props){
    const {setHoverID, setHoverType} = props;
    const {ManaCardCount, ManaIcons, ElementCosts, activeDeck, GetCardData, RemoveCard, SaveDeck, myDecks, addDeck, setActiveDeck, DeleteDeck, deckWindow, setDeckWindow} = useDbContext();
    const [elementFilter, setElementFilter] = useState("all");
    const [openTab, setOpenTab] = useState("decks");
    const [showCards, setShowCards] = useState("main");
    const [editDeck, setEditDeck] = useState(false);

    function CardCount(){
        let am = 0;
        if(!activeDeck) return 0;
        if(activeDeck.maindeck.length > 0){
            for(let i = 0; i < activeDeck.maindeck.length; i++){
                am += activeDeck.maindeck[i].amount;
            }
        }
        return am;
    }

    return (
        <div className={"flex flex-col w-[500px] h-full overflow-y-hidden bg-[#01130FF7] bg-opacity-40 border-l " + bordercolor}>
            {openTab === "decks" ?
                <DeckHeader setEditDeck={setEditDeck} openTab={openTab} setOpenTab={setOpenTab} editDeck={editDeck} />
                :
                <div className={"flex-row flex justify-center text-lg font-semibold items-center w-full h-[70px] border-b " + bordercolor}>
                 <Tooltip title={"Cancel"}><Button onClick={() => { setOpenTab("decks");}} className={"flex flex-row h-12 w-14 justify-center items-center"}><ArrowBack /></Button></Tooltip>
                    <p className={"flex flex-row w-hull w-full justify-center items-center"}> Select deck</p>
                    <div className={"flex flex-row h-full w-10"}></div>
                </div>
            }
            {openTab === "select" &&
                <div className={"flex flex-col w-full h-full overflow-y-hidden"}>
                    <div className={"flex flex-col w-full overflow-hidden h-full border-b " + bordercolor}>
                        <div className={"flex flex-col w-full h-full justify-start items-center overflow-y-auto"}>
                            {myDecks && myDecks.map((deck, ind) => (
                                <DeckListEntry key={ind} editDeck={editDeck} setEditDeck={setEditDeck} deck={deck} />
                            ))}
                        </div>
                    </div>
                    <div className={"flex flex-row h-[100px] w-full justify-around items-center"}>
                        <div className={"flex flex-row h-full items-center justify-around w-full"}>
                            <div
                                className={"flex flex-row w-auto px-2 h-10 py-2 bg-green-700 hover:bg-opacity-60 my-1 justify-center items-center"}
                                onClick={() => {
                                    let nd = {}
                                    nd.deckName = "New Deck";
                                    nd.publicshare = false;
                                    nd.description = "";
                                    nd.maindeck = [];
                                    nd.bench = [];
                                    nd.origin = -1;
                                    nd.resourcesdeck = [];
                                    addDeck(nd);
                                }}>
                                <AddCircleOutlineIcon/>Create New
                            </div>

                            <div className={"flex flex-row w-auto px-2 h-10 py-2 hover:bg-opacity-60 my-1 justify-center items-center " + (deckWindow ? "bg-green-500" : "bg-green-900")}
                                onClick={() => {
                                    setDeckWindow(!deckWindow);
                                }}>
                                Public Decks
                            </div>
                        </div>
                    </div>
                </div>
            }

            {openTab === "decks" &&
                <div className={"flex flex-col w-full h-full overflow-y-hidden"}>
                    <div className={"flex flex-row w-full h-[50px] overflow-y-hidden justify-center items-center border-b " + bordercolor}>
                        <Tooltip title={"Click to show units and actions in deck."}>
                            <div className={showCards === "main" ? "bg-green-700" : ""}>
                                <Button onClick={() => setShowCards("main")} >Main</Button>
                            </div>
                        </Tooltip> |
                        <Tooltip title={"Click to show mana cards in deck."}>
                            <div className={showCards === "mana" ? "bg-green-700" : ""}>
                            <Button onClick={() => setShowCards("mana")}>Mana</Button>
                            </div>
                        </Tooltip> |
                        <Tooltip title={"Click to show bench cards in deck."}>
                            <div className={showCards === "bench" ? "bg-green-700" : ""}>
                                <Button onClick={() => setShowCards("bench")}>Bench</Button>
                            </div>
                        </Tooltip>
                    </div>
                    {showCards !== "bench" && <div className={"flex flex-row h-[60px] justify-center items-center gap-1 py-2 px-2 w-full border-b " + bordercolor}>
                        {showCards === "main" && <>
                        <ElementCounter str={"earth"} setElementFilter={setElementFilter}
                                        elementFilter={elementFilter}/>
                        <ElementCounter str={"flame"} setElementFilter={setElementFilter}
                                        elementFilter={elementFilter}/>
                        <ElementCounter str={"metal"} setElementFilter={setElementFilter}
                                        elementFilter={elementFilter}/>
                        <ElementCounter str={"lunar"} setElementFilter={setElementFilter}
                                        elementFilter={elementFilter}/>
                        <ElementCounter str={"solar"} setElementFilter={setElementFilter}
                                        elementFilter={elementFilter}/>
                        <ElementCounter str={"water"} setElementFilter={setElementFilter}
                                        elementFilter={elementFilter}/>
                        </>}
                        {showCards === "mana" && <>
                            <ManaCounter str={"earth"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ManaCounter str={"flame"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ManaCounter str={"metal"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ManaCounter str={"lunar"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ManaCounter str={"solar"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ManaCounter str={"water"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                        </>}
                    </div>}

                    <div className={"flex flex-col w-full overflow-hidden h-full border-b " + bordercolor}>
                        <div className={"flex flex-col w-full h-full justify-start items-center overflow-y-auto"}>
                            {showCards === "mana" && activeDeck && activeDeck.resourcesdeck && activeDeck.resourcesdeck.length > 0 && activeDeck.resourcesdeck.map((crd, ind) => (
                                <div key={ind} onMouseLeave={() => {
                                    setHoverID("");
                                    setHoverType("");
                                }} onMouseEnter={() => {
                                    setHoverID(crd.Id);
                                    setHoverType("mydecks");
                                }}
                                     className={"flex px-4 flex-row text-sm hover:bg-opacity-70 h-14 w-full py-4 justify-start items-center border-b " + bordercolor + (ind % 2 === 0 ? " bg-slate-800 bg-opacity-30" : " bg-slate-700 bg-opacity-30")}>
                                    <p>{crd.amount}x</p>
                                    <p className={"flex w-10"}></p>
                                    <p className={"flex flex-row w-52 h-full justify-start items-center"}>{crd.Name}</p>
                                    <div className={"flex flex-row w-24 h-8 gap-1 justify-start items-center"}>
                                        <ManaIcons ele={GetCardData(crd.Id).race.toLowerCase()}/>
                                    </div>
                                </div>
                            ))}

                            {showCards === "main" && activeDeck && activeDeck.maindeck && activeDeck.maindeck.length > 0 && activeDeck.maindeck.map((crd, ind) => (
                                <CardListEntry key={ind} crd={crd} ind={ind} setHoverType={setHoverType} setHoverID={setHoverID} bench={false}  />
                            ))}

                            {showCards === "bench" && activeDeck && activeDeck.bench && activeDeck.bench.length > 0 && activeDeck.bench.map((crd, ind) => (
                                <CardListEntry key={ind} crd={crd} ind={ind} setHoverType={setHoverType} setHoverID={setHoverID} bench={true}  />
                            ))}

                        </div>
                    </div>
                    <div className={"flex flex-row h-[100px] w-full justify-between items-center"}>
                        <div className={"flex flex-row w-full h-full justify-center items-center"}>
                            <div className={"flex flex-row w-1/2 gap-2 h-full justify-center items-center"}>
                                {showCards === "main" && <p className={"flex flex-row justify-center items-center h-full w-auto"}>All Costs</p>}
                                {showCards === "main" && <ElementCounter str={"all"} setElementFilter={setElementFilter}
                                                elementFilter={elementFilter}/>}
                            </div>
                            <div className={"flex flex-row w-1/2 h-full justify-center items-center"}>
                                {showCards === "main" &&<div className={"flex flex-row w-full h-full justify-center items-center"}>{CardCount()}/50
                                    cards</div>}
                                {showCards === "mana" &&<div className={"flex flex-row w-full h-full justify-center items-center"}>{ManaCardCount()}/12
                                    cards</div>}
                                {showCards === "bench" &&<div className={"flex flex-row w-full h-full justify-center items-center"}>{activeDeck ? activeDeck.bench.length : 0}/10
                                    cards</div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

function DeckHeader(props) {
    const {activeDeck, SaveDeck} = useDbContext();
    const {setEditDeck, openTab, setOpenTab, editDeck} = props;
    const [deckDescription, setDeckDescription] = useState("");
    const [tempName, setTempName] = useState("");
    const [pshare, setPShare] = useState(false);

    useEffect(() => {
        if (activeDeck) {
            setDeckDescription(activeDeck.description);
            setTempName(activeDeck.deckName);
            setPShare(activeDeck.publicshare);
        }
    }, [activeDeck]);

    function Save() {
        activeDeck.description = deckDescription;
        activeDeck.deckName = tempName;
        SaveDeck();
    }

    useEffect(() => {
        activeDeck.publicshare = pshare;
        SaveDeck();
    }, [pshare]);

    function GetCardList(){
        let ls = [];
        for(let i = 0; i < activeDeck.maindeck.length; i++) {
            let nc = { name: activeDeck.maindeck[i].Name, link: "https://play.manacaster.com/cards/" + activeDeck.maindeck[i].Id + ".png" };
            ls.push(nc);
        }
        let ls2 = [];
        for(let i = 0; i < activeDeck.resourcesdeck.length; i++) {
            let nc = { name: activeDeck.resourcesdeck[i].Name, link: "https://play.manacaster.com/cards/" + activeDeck.resourcesdeck[i].Id + ".png" };
            ls2.push(nc);
        }
        return JSON.stringify({maindeck: ls, manadeck: ls2});
    }

    if(editDeck) {
        return (
            <div className={"flex flex-col w-full h-[350px] border-b " + bordercolor}>
                <div className={"flex flex-row w-full h-[80px] "}>
                    <div
                        className={"flex-row flex justify-center text-lg font-semibold items-center w-full h-full"}>
                        <input
                            className={"flex p-1 flex-row mt-1 w-[90%] h-10 justify-center items-center bg-[#244939] border border-[#51b78c]"}
                            value={tempName}
                            onBlur={(e) => {
                                Save();
                            }}
                            onChange={(e) =>
                                setTempName(e.currentTarget.value)}/>
                    </div>
                </div>
                <div className={"h-full w-full flex flex-col justify-center items-center"}>
                <div className={"flex flex-row h-[100px] w-full py-1 px-4"}>
                        <textarea onBlur={(e) => {
                            Save();
                        }} onChange={(e) => setDeckDescription(e.currentTarget.value)} value={deckDescription}
                                  className={"flex flex-row w-full h-full bg-[#244939] border border-[#51b78c] p-1"}></textarea>
                    </div>
                    <div className={"flex flex-row h-12 w-full justify-around items-center"}>
                        <Checkbox onChange={(e) => {
                            setPShare(e.currentTarget.checked);
                        }} checked={pshare}/>Share Publicly
                        <Button onClick={() => setEditDeck(null)}>Close Edit</Button>
                    </div>
                </div>
                <div className={"flex flex-row h-12 w-full justify-center items-center"}>
                    <Button onClick={() => {navigator.clipboard.writeText(GetCardList())}}>
                        Cards list to Clipboard
                    </Button>
                </div>
            </div>
        )
    } else return (
        <div className={"flex flex-row w-full h-[70px] border-b " + bordercolor}>
            <Tooltip title={"Swap to deck selection"}>
                <Button onClick={() => {
                    if (openTab === "decks") setOpenTab("select");
                }} className={"flex flex-row h-full w-auto justify-center items-center"}>
                    <SwapHorizIcon/>
                </Button>
            </Tooltip>
            <div
                className={"flex-row flex justify-center text-lg font-semibold items-center w-full h-full"}>{activeDeck.deckName}</div>
            <Button onClick={() => {
                setEditDeck(!editDeck);
            }}
                    className={"flex-row flex justify-center text-lg font-semibold items-center w-auto h-full"}>
                <EditOutlined/>
            </Button>
        </div>
    )
}

function DeckListEntry(props) {
    const {activeDeck, setActiveDeck, DeleteDeck} = useDbContext();
    const {deck, editDeck} = props;

    function DeckColor() {
        if (editDeck === deck) return " bg-slate-500 bg-opacity-50"
        if (activeDeck && activeDeck.id === deck.id) return " bg-[#1F59419E] bg-opacity-40";
       else return " bg-[#3b6f5e26] bg-opacity-40";
    }

    return (

    <div key={deck.id}
         className={(editDeck === deck ? "h-[200px] " : "h-14 ") + " flex flex-col hover:bg-opacity-70 justify-center items-center w-full border-b " + bordercolor + DeckColor()}>
                <div className={"flex flex-row h-14 w-full "}>
                    <div onClick={() => setActiveDeck(deck)} className={"flex pl-4 w-10 h-full justify-center items-center"}>
                        <input type="radio" name="radio-2" className="radio radio-primary"
                              onChange={(e) => { if(e.currentTarget.checked) setActiveDeck(deck)}} checked={activeDeck && activeDeck.id === deck.id}/>
                        </div>
                    <div className={"flex w-full h-full justify-center items-center"} onClick={() => setActiveDeck(deck)}>
                    {deck.deckName}
                        </div>
                    <ConfirmDialog deckEntry={deck} DeleteDeck={DeleteDeck}/>
                </div>
            </div>
    )
}

function CardListEntry(props) {
    const {setHoverID, ind, crd, bench, setHoverType} = props;
    const {GetCardData, ElementCosts, RemoveCard, activeDeck, SaveDeck, ZeroCard, UnZeroCard, RemoveBench} = useDbContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorPosX, setAnchorPosX] = useState(0);
    const [anchorPosY, setAnchorPosY] = useState(0);
    const open = Boolean(anchorEl);

    let card = GetCardData(crd.Id);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setAnchorPosX(event.clientX);
        setAnchorPosY(event.clientY);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRemove = () => {
        if(bench) {
            if (RemoveBench(crd) && activeDeck.id !== "") SaveDeck();
            setAnchorEl(null);
        } else {
            if (RemoveCard(crd) && activeDeck.id !== "") SaveDeck();
            setAnchorEl(null);
        }
    }

    const handleZero = () => {
        if (ZeroCard(crd) && activeDeck.id !== "") SaveDeck();
        setAnchorEl(null);
    }

    const handleRestoreCard = () => {
        if (UnZeroCard(crd) && activeDeck.id !== "") SaveDeck();
        setAnchorEl(null);
    }

    const col = "rgba(31,89,65,0.62)";

    function CardColor() {
        if(crd.amount > 0) return (ind % 2 === 0 ? " bg-[#1F59419E] bg-opacity-30" : " bg-[#3b6f5e26] bg-opacity-50")
        else return " bg-red-700 bg-opacity-50";
    }

    return (
        <>
            <div key={ind} onMouseLeave={() => {
                setHoverID("");
                setHoverType("");
            }} onMouseEnter={() => {
                setHoverID(crd.Id);
                setHoverType("mydecks");
            }}
                 id="card-button"
                 aria-controls={open ? 'card-menu' : undefined}
                 aria-haspopup="true"
                 aria-expanded={open ? 'true' : undefined}
                 onMouseDown={handleClick}
                 className={"flex px-4 flex-row text-sm hover:bg-opacity-70 h-14 w-full py-4 justify-start items-center border-b " + bordercolor + CardColor()}>
                <p className={"flex w-10"}></p>
                <p className={"flex flex-row w-52 h-full justify-start items-center"}>{crd.Name}</p>
                <div className={"flex flex-row w-24 h-8 gap-1 justify-start items-center"}>
                    {card && card.costs && card.costs.map((el) => (
                        <ElementCosts key={crd.Id + el.element} element={el.element}
                                      amount={el.amount}/>
                    ))}
                </div>
            </div>
            <Menu
                id="card-menu"
                anchorReference={"anchorPosition"}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'card-button',
                }}
                anchorPosition={{ left: anchorPosX, top: anchorPosY }}
            >
                {!bench && (crd.amount > 0 ? <MenuItem onClick={handleZero}>Set to 0</MenuItem > : <MenuItem onClick={handleRestoreCard}>Restore</MenuItem>)}
                <MenuItem onClick={handleRemove}>Remove</MenuItem>
            </Menu>
        </>
    );
}


const selbuttonCol = "bg-[#4CB190C3]"
const buttonCol = "bg-[#3b6f5e26]"

function ElementCounter(props) {
    const {elementFilter, str, setElementFilter} = props;
    const {activeDeck, GetCardData, TotalCosts} = useDbContext();

    if (str === "all") {
        return (
            <div
                className={"flex flex-row h-10 w-12 justify-center items-center px-1 hover:bg-opacity-60 " + (elementFilter === "all" ? selbuttonCol : buttonCol)}
                onClick={() => {
                    setElementFilter("all")
                }}>
                <div className={"flex justify-center items-center h-full w-8 text-xs uppercase "}><TotalCosts
                    str={str}/></div>
            </div>
        )
    } else {
        return (
            <div
                className={"flex flex-row h-10 w-[100px] justify-center pl-2 items-center hover:bg-opacity-60 " + (elementFilter === str.toLowerCase() ? selbuttonCol : buttonCol)}
                onClick={() => {
                    setElementFilter(str.toLowerCase());
                }}>
                <img className={"h-6 w-auto"} src={"/images/" + str + ".png"} alt={str}/>
                <div className={"flex justify-center items-center h-full w-8"}><TotalCosts str={str}/></div>
            </div>
        )
    }
}

function ManaCounter(props) {
    const {elementFilter, str, setElementFilter} = props;
    const {ResourceAmount} = useDbContext();

    return (
        <div
            className={"flex flex-row h-10 w-14 justify-center px-2 items-center hover:bg-opacity-60 " + (elementFilter === str.toLowerCase() ? selbuttonCol : buttonCol)}
            onClick={() => {
                setElementFilter(str.toLowerCase());
            }}>
            <img className={"h-6 w-auto"} src={"/images/" + str + ".png"} alt={str}/>
            <div className={"flex justify-center items-center h-full w-8 "}><ResourceAmount str={str}/></div>
        </div>
    )
}