import {bordercolor} from "../theme";
import {Card} from "./cardentries";
import React, {useEffect, useState} from "react";
import {useDbContext} from "../contexts/dbContext";

export default function CardBox(props){
    const scrollRef = React.useRef(null);
    const {setCurrentPage, pages, displayCards, currentPage, currentPageLabel, setCurrentPageLabel} = useDbContext();

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
        setCurrentPage(currentPageLabel - 1);
    }, [currentPageLabel, scrollRef]);

    const handleScroll = (e) => {
        const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;
        let nextpage = currentPageLabel;

        if (scrollTop + clientHeight === scrollHeight && e.deltaY > 0) {
            if (currentPageLabel < pages.length) nextpage++;
            setCurrentPageLabel(nextpage);
        } else if (scrollTop === 0 && e.deltaY < 0) {
            if (currentPageLabel > 1) nextpage--;
            setCurrentPageLabel(nextpage);
        }
    };

    return (
        <div id="container"
             className={`flex flex-col h-full w-full mb-10 lg:mb-0 overflow-y-auto overflow-x-hidden border-l ` + bordercolor}
             ref={scrollRef}
             onWheel={e => {
                 handleScroll(e);
             }}>
                {displayCards.length > 0 && pages.length > 0 && pages.map((el, ind) =>
                    <div key={ind} className={(ind === currentPage ? "flex relative " : "hidden ") + " flex-wrap h-auto w-full gap-1 my-auto justify-center items-center py-2"}>
                        {el.cards.map((el2, ind2) =>
                        <Card key={ind2} card={el2} type={el2.type}/>
                        )}
                    </div>
                    )}
        </div>
    )
}