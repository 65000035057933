import React, {useState} from "react";
import {useDbContext} from "../contexts/dbContext";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PreviewIcon from '@mui/icons-material/Preview';
import Button from "@mui/material/Button";
import {Checkbox, Input, Switch, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {useAppContext} from "../contexts/appContext";
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import AddIcon from '@mui/icons-material/Add';
import {bordercolor} from "../theme";
import {dbstyle} from "./dbstylings";
import Tooltip from "@mui/material/Tooltip";

export default function PublicDecks(props){
    const {hideMenu, hoverId, setHoverID, fullpage, setHoverType} = props;
    const {decks, getdecks, deckWindow, setDeckWindow} = useDbContext();
    const [searchText, setSearchText] = useState("");
    const [showOwn, setShowOwn] = useState(false);
    const [showSameName, setShowSameName] = useState(false);
    const [hideAdded, setHideAdded] = useState(false);

    const col = "#091c1e";

    return (
        <div className={bordercolor + (fullpage ? " flex h-full w-[80%] border-r " : " absolute h-[85%] lg:h-[85%] lg:w-[80%] w-full rounded-2xl border ") +  " flex-col left-[10px] bg-[#091C1EFF] overflow-hidden " + (hideMenu ? "top-[9%]" : "top-[9%]")}>
            {!fullpage &&  <div className={"flex flex-row h-10 w-full justify-between px-2"}>
                <div>

                </div>
                <p className={"flex flex-row h-full justify-center items-center text-lg"}>Public Decks</p>
                <Button onClick={() => setDeckWindow(false)}>
                    X
                </Button>
            </div>}
            <div className={"flex flex-row h-full w-full overflow-y-hidden overflow-x-auto"}>
                <div className={"flex flex-row h-full w-full gap-2 justify-around items-center px-2"}>
                    <div className={"flex flex-row w-full h-full justify-center items-center py-2"}>
                        <div className={"flex flex-col w-full h-full overflow-y-hidden overflow-x-hidden"}>
                            <div className={"flex flex-row h-14 w-full bg-[#3b6f5e26]"}>
                                <div className={"flex flex-row h-full items-center justify-center w-12"}></div>
                                <div className={"flex flex-row h-full items-center justify-center w-1/6"}>Rating</div>
                                <div className={"flex flex-row h-full items-center justify-center w-3/6"}>Deck Name
                                </div>
                                <div className={"flex flex-row h-full items-center justify-center w-1/6"}>Creator</div>
                                <div className={"flex flex-row h-full items-center justify-center w-1/6"}>
                                    <Checkbox
                                        checked={hideAdded}
                                        onChange={(e) => setHideAdded(e.currentTarget.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    Hide Added
                                </div>
                            </div>
                            <div className={"flex flex-col w-full h-full gap-2 overflow-y-auto overflow-x-hidden"}>
                                {decks && decks.map((el, ind) => (
                                    <Deck hideAdded={hideAdded} setHoverType={setHoverType} setHoverID={setHoverID} hoverId={hoverId} key={ind} deckentry={el}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const col = "#1d232b"

function Deck(props) {
    const {cardsOnly, session, cardsdate} = useAppContext();
    const {deckentry, hoverId, setHoverID, setHoverType, hideAdded} = props;
    const {addDeck, calcRating, upvote, downvote, Preview, HasDeck} = useDbContext();
    const {id} = useAppContext();
    const [manaOpen, setManaOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);

    // if (!isVisible(deckentry)) return <></>;
    // else

    return (
        <div className={(hideAdded && HasDeck(deckentry.id) ? "hidden " : "flex ") + " flex-col w-full select-none " + (infoOpen ? "h-[500px] pb-2 bg-[#1D232BFF]" : "h-10 bg-[#2c3740]")}>
            <div onClick={() => {
                setInfoOpen(!infoOpen);
            }}
                 className={"flex flex-row h-10 w-full cursor-pointer " + (infoOpen ? "bg-black bg-opacity-30 hover:bg-opacity-50" : "hover:bg-black hover:bg-opacity-30")}>

                <div className={"flex flex-col w-12 h-full justify-center items-center"}>
                    {infoOpen ? <ArrowDropUpOutlinedIcon/> : <ArrowDropDownOutlinedIcon/>}
                </div>
                <div className={"flex flex-row w-1/6 justify-center items-center h-full"}>
                    <div style={{display: !infoOpen ? "flex" : "none"}}
                         className={"font-semibold " + (calcRating(deckentry) < 0 ? " text-red-300" : " text-green-300")}>{calcRating(deckentry)}</div>
                </div>
                <div className={"flex flex-row h-full items-center justify-center w-3/6"}>{deckentry.deckName}</div>
                <div
                    className={"flex flex-row h-full items-center justify-center w-1/6"}>{deckentry.ownerName}</div>
                <div className={"flex flex-row h-full items-center justify-center w-1/6"}>

                </div>
            </div>
            {infoOpen &&
                <div className={"flex flex-col h-full w-full overflow-hidden"}>
                    <div className={"flex flex-row h-10 w-full justify-center items-center"}>
                        <div className={"flex flex-row h-full items-center justify-center w-12"}></div>
                        <div className={"flex flex-row h-10 w-1/6 justify-center items-center"}>
                            <div onClick={() => {
                                upvote(deckentry);
                            }}
                                 style={{display: (deckentry.upvotes.includes(id) || deckentry.downvotes.includes(id) ? "none" : "flex")}}
                                 className={"flex flex-row h-10 w-10 justify-center items-center"}
                            >
                                <ArrowCircleUpTwoToneIcon/>
                            </div>
                            <div
                                className={"flex flex-row h-10 w-10 justify-center items-center font-semibold " + (calcRating(deckentry) < 0 ? " text-red-300" : " text-green-300")}>{calcRating(deckentry)}</div>
                            <div onClick={() => {
                                downvote(deckentry);
                            }}
                                 style={{display: (deckentry.upvotes.includes(id) || deckentry.downvotes.includes(id) ? "none" : "flex")}}
                                 className={"flex flex-row h-10 w-10 justify-center items-center"}>
                                <ArrowCircleDownTwoToneIcon/>
                            </div>
                        </div>
                        <div className={"flex flex-row h-10 w-4/6 justify-center items-center"}>
                        </div>
                        <div className={"flex flex-row h-10 w-1/6 justify-center items-center"}>
                            {!HasDeck(deckentry.id) ? <Tooltip title={"Add to my decks"} placement="top">
                                <Button onClick={() => {
                                    const clone = (({id, created_at, ...o}) => o)(deckentry);
                                    clone.origin = deckentry.id;
                                    addDeck(clone);
                                }}>
                                    Add to My Decks
                                </Button>
                            </Tooltip> : <div>
                                Already added
                            </div>}
                        </div>
                    </div>
                    <div className={"flex flex-row h-10 w-3/6 flex-wrap justify-center items-center"}>
                        {deckentry.description}
                    </div>
                    <div className={"flex flex-row h-10 w-full justify-around items-center"}>
                        <div className={"py-2"}>Cards in <Button onClick={() => {
                            setManaOpen(false)
                        }}>Main</Button>|<Button onClick={() => {
                            setManaOpen(true)
                        }}>Mana</Button> Deck
                        </div>
                    </div>
                    {manaOpen ?
                        <div className={"flex flex-col h-full items-center overflow-hidden justify-center w-full"}>
                            <div
                                className={"flex flex-wrap w-full h-full justify-center items-center overflow-y-auto p-2 gap-2"}>
                                {deckentry.resourcesdeck.map((card, ind) => (
                                    <div key={ind} className={"flex flex-row rounded-lg justify-end items-end " + dbstyle.cards.notselected}
                                         decoding="async"
                                         style={{
                                             cursor: 'context-menu',
                                             height: 1040 * 0.2,
                                             width: 744 * 0.2,
                                             backgroundSize: "contain",
                                             backgroundImage: "url(cards/" + card.Id + ".png?key=" + cardsdate + ")"
                                         }} onMouseEnter={() => {
                                        setHoverID(card.Id);
                                        setHoverType("decks");
                                    }} onMouseLeave={() => {
                                        setHoverType("");
                                        setHoverID("");
                                    }}>
                                        <p className={"flex w-full h-10 justify-end items-end text-2xl shadow-black shadow text-green-300 p-1"}>{"x" + card.amount}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <div className={"flex flex-col h-full items-center overflow-hidden justify-center w-full"}>
                            <div
                                className={"flex flex-wrap w-full h-full justify-center items-center overflow-y-auto p-2 gap-2"}>
                                {deckentry.maindeck.map((card, ind) => (
                                    <div key={ind} className={"flex flex-row rounded-lg " + dbstyle.cards.notselected}
                                         style={{
                                             cursor: 'context-menu',
                                             height: 1040 * 0.2,
                                             width: 744 * 0.2,
                                             backgroundSize: "contain",
                                             backgroundImage: "url(cards/" + card.Id + ".png?key=" + cardsdate + ")"
                                         }} onMouseEnter={() => {
                                        setHoverType("decks");
                                        setHoverID(card.Id);
                                    }} onMouseLeave={() => {
                                        setHoverID("");
                                        setHoverType("");
                                    }}></div>
                                ))}
                            </div>
                        </div>}
                </div>}
        </div>
    )
}

const sdeckstyle = "flex flex-col h-full w-[500px] bg-header items-center justify-start rounded-2xl px-4";
const sdeckbutton = "flex h-14 w-full justify-center items-center bg-secondary";

function NewPlayerWindow() {
    const hideMenu = true;
    return (
        <div
            className={"absolute flex flex-col lg:px-6 h-[80%] lg:h-[80%] w-full lg:w-[70%] lg:left-[15%] bg-footer rounded-2xl overflow-hidden " + (hideMenu ? "top-[9%]" : "top-[15%]")}>
            <div className={"flex flex-row h-14 w-full justify-center items-center text-primary"}>
                <p>Welcome new player discover some starter decks below.</p>
            </div>
            <div className={"flex flex-row h-full w-full overflow-y-hidden overflow-x-auto pb-8"}>
                <div className={"flex flex-row h-full w-auto gap-2 justify-center items-center"}>
                    <div className={sdeckstyle}>
                        <p className={"flex flex-row h-14 w-full text-center justify-center items-center text-lg font-bold"}>Starter
                            Deck A</p>
                        <div className={"flex flex-row w-full h-[70%] justify-center items-center"}>
                            <div
                                className={"flex flex-col w-20 h-full justify-between items-center pt-4 gap-2 text-xs"}>
                                <div
                                    className={"flex flex-col w-20 h-auto justify-start items-center pt-4 gap-2 text-xs"}>
                                    <div className={sdeckbutton}>Units(0)</div>
                                    <div className={sdeckbutton}>Actions(0)</div>
                                    <div className={sdeckbutton}>Mana(0)</div>
                                </div>

                                <div className={sdeckbutton + " justify-self-end self-end"}>
                                    Add Deck
                                </div>
                            </div>

                            <div className={"h-full w-full overflow-x-hidden overflow-y-auto"}>
                                <div className={"flex flex-wrap justify-center items-center w-full h-auto gap-2"}>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={"flex flex-wrap h-auto min-h-20 w-full text-center justify-center items-center"}>
                            This is a starter deck description sample
                        </p>
                    </div>

                    <div className={sdeckstyle}>
                        <p className={"flex flex-row h-14 w-full text-center justify-center items-center text-lg font-bold"}>Starter
                            Deck A</p>
                        <div className={"flex flex-row w-full h-[70%] justify-center items-center"}>
                            <div
                                className={"flex flex-col w-20 h-full justify-between items-center pt-4 gap-2 text-xs"}>
                                <div
                                    className={"flex flex-col w-20 h-auto justify-start items-center pt-4 gap-2 text-xs"}>
                                    <div className={sdeckbutton}>Units(0)</div>
                                    <div className={sdeckbutton}>Actions(0)</div>
                                    <div className={sdeckbutton}>Mana(0)</div>
                                </div>

                                <div className={sdeckbutton + " justify-self-end self-end"}>
                                    Add Deck
                                </div>
                            </div>

                            <div className={"h-full w-full overflow-x-hidden overflow-y-auto"}>
                                <div className={"flex flex-wrap justify-center items-center w-full h-auto gap-2"}>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={"flex flex-wrap h-auto min-h-20 w-full text-center justify-center items-center"}>
                            This is a starter deck description sample
                        </p>
                    </div>

                    <div className={sdeckstyle}>
                        <p className={"flex flex-row h-14 w-full text-center justify-center items-center text-lg font-bold"}>Starter
                            Deck A</p>
                        <div className={"flex flex-row w-full h-[70%] justify-center items-center"}>
                            <div
                                className={"flex flex-col w-20 h-full justify-between items-center pt-4 gap-2 text-xs"}>
                                <div
                                    className={"flex flex-col w-20 h-auto justify-start items-center pt-4 gap-2 text-xs"}>
                                    <div className={sdeckbutton}>Units(0)</div>
                                    <div className={sdeckbutton}>Actions(0)</div>
                                    <div className={sdeckbutton}>Mana(0)</div>
                                </div>

                                <div className={sdeckbutton + " justify-self-end self-end"}>
                                    Add Deck
                                </div>
                            </div>

                            <div className={"h-full w-full overflow-x-hidden overflow-y-auto"}>
                                <div className={"flex flex-wrap justify-center items-center w-full h-auto gap-2"}>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={"flex flex-wrap h-auto min-h-20 w-full text-center justify-center items-center"}>
                            This is a starter deck description sample
                        </p>
                    </div>
                </div>
            </div>
            <div className={"hidden lg:flex flex-row h-14 w-full"}>

            </div>
        </div>
    )
}