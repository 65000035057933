import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import HomeIcon from "@mui/icons-material/Home";
import Tooltip from "@mui/material/Tooltip";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ForumIcon from '@mui/icons-material/Forum';
import FriendList from "./friendlist";
import SocialDash from "./socialdash";
import ChannelView from "./channels";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Requests from "./requests";
import {Badge} from "@mui/material";
import {useSocialContext} from "../contexts/socialContext";

export function SocialPanel(props) {
    const {panelOpen, setPanelOpen} = props;
    const {RequestsCounter, ChannelCounter} = useSocialContext();
    const [sbOpen, setSbOpen] = useState(false);
    const [openTab, setOpenTab] = useState("dashboard");

    const tabname = (str) => {
        switch(str){
            case "requests":
                return "Requests";
            case "dashboard":
                return "Dashboard";
            case "friends":
                return "Friends";
                case "channels":
                    return "Global Chat";
                    default:
                        return "Dashboard";
        }
    }

    return(
        <div style={{display: panelOpen ? "flex" : "none", height: "90%", top: 80, right:0}} className={"fixed flex-col w-full lg:w-1/3 bg-[#193025FF] border-l border-b border-[#51b78c] border-opacity-60 overflow-hidden"}>
            <div className={"flex flex-row h-14 w-full px-2 bg-black bg-opacity-40 border-b border-[#51b78c] border-opacity-40 items-center py-2 justify-between"}>
                <div className={"flex flex-row h-10 w-10 items-center py-2 justify-center"}>
                    <Tooltip title="Sidebar" placement="right">
                        <div style={{display: openTab === "dashboard" || openTab === "requests" ? "none" : "flex"}}
                             className={(sbOpen ? "bg-[#51b78c] " : "bg-[#3b6f5e26] ") + " flex-row h-10 w-10 cursor-pointer border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                             onClick={() => {
                                 setSbOpen(!sbOpen)
                             }}
                        >
                            {sbOpen ? <KeyboardDoubleArrowLeftIcon/> : <KeyboardDoubleArrowRightIcon/>}
                        </div>
                    </Tooltip>
                </div>
                <div className={"flex flex-row h-auto w-auto text-lg"}>{tabname(openTab)}</div>
                <div className={"flex flex-row h-10 w-auto gap-3 items-center py-2 px-2 justify-center"}>
                    <Tooltip title="Social Dashboard" placement="bottom">
                        <div
                            className={(openTab === "dashboard" ? "bg-[#51b78c]" : "bg-[#3b6f5e26] ") + " flex flex-row h-10 w-10 cursor-pointer border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                            onClick={() => {
                                setOpenTab("dashboard")
                            }}
                        >
                            <HomeIcon/>
                        </div>
                    </Tooltip>

                    <Tooltip title="Friends" placement="bottom">
                        <div

                            className={(openTab === "friends" ? "bg-[#51b78c]" : "bg-[#3b6f5e26] ") + " flex flex-row h-10 w-10 cursor-pointer border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                            onClick={() => {
                                setOpenTab("friends")
                            }}
                        >
                            <PeopleAltIcon/>
                        </div>
                    </Tooltip>
                    <Badge badgeContent={ChannelCounter()} color="success" anchorOrigin={{vertical: 'top', horizontal: "left"}}>
                        <Tooltip title="Channels" placement="bottom">
                            <div
                                className={(openTab === "channels" ? "bg-[#51b78c]" : "bg-[#3b6f5e26] ") + " flex flex-row h-10 w-10 border cursor-pointer border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                                onClick={() => {
                                    setOpenTab("channels")
                                }}
                            >
                                <ForumIcon/>
                            </div>
                        </Tooltip>
                    </Badge>
                    <Badge badgeContent={RequestsCounter()} color="success" anchorOrigin={{vertical: 'top', horizontal: "left"}}>
                        <Tooltip title="Requests" placement="bottom">
                            <div
                                className={(openTab === "requests" ? "bg-[#51b78c]" : "bg-[#3b6f5e26] ") + " flex flex-row h-10 w-10 border cursor-pointer border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                                onClick={() => {
                                    setOpenTab("requests")
                                }}
                            >
                                <NotificationsIcon/>
                            </div>
                        </Tooltip>
                    </Badge>
                </div>
            </div>
            <div className={"flex flex-row h-full w-full overflow-hidden"}>
                {openTab === "dashboard" && <SocialDash />}
                {openTab === "requests" && <Requests />}
                {openTab === "friends" && <FriendList sbOpen={sbOpen} setSbOpen={setSbOpen} />}
                {openTab === "channels" && <ChannelView  sbOpen={sbOpen} setSbOpen={setSbOpen}/>}
            </div>
        </div>
    )
}