import React, {useState} from "react";
import {bordercolor} from "../theme";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import {Checkbox, ListItemText, OutlinedInput, ToggleButton, ToggleButtonGroup} from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import {useAppContext} from "../contexts/appContext";
import {Link} from "react-router-dom";
import {useDbContext} from "../contexts/dbContext";
import SortMenu from "./sortmenu";
import {Print} from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function DbNavBar(props) {
    const {
        dsOpen,
        setDsOpen,
        startsearch,
        setSt,
        st,
        handleDisplayChange,
        handleKWChange,
        dpOpen,
        setDpOpen,
    } = props;
    const {        DisplayMode,
        setSortType,
        IdentityFilter,
        setIdentityFilter,
        identities,
        keywordFilter,
        keywords,
        setKeywordFilter,
        cardSize,
        setCardSize,
        cardsPerPage,
        setCardsPerPage,
        starterScreen,
        setSearchText,
        InDeck,
        SetInDeck,
        myDecks,
        deckfilterActive,
        setDeckfilterActive,
        editions,
        setEdition,
        edition,
        setStarterScreen} = useDbContext();
    const [filtersOpen, setFiltersOpen] = useState(false);
    const {hideMenu, setHideMenu, session, cardsOnly, isAdmin} = useAppContext();

    return (
        <div
            className={"flex w-full items-center justify-between border-b h-auto lg:h-16 flex-row  bg-[#01130FF7] bg-opacity-40 " + bordercolor}>
            <div className={"flex flex-row w-full h-auto lg:h-14 items-center justify-between p-2"}>
                <div className={"flex flex-row w-full h-12 lg:h-full items-center justify-between p-2"}>
                    <div className={"flex flex-row w-1/3 h-full justify-between items-center gap-2"}>
                        {hideMenu &&
                            <Link to={"/"}>
                                <Button color={"primary"}
                                        className={"flex flex-row w-12 h-12 justify-center items-center "}
                                        onClick={() => {
                                            setHideMenu(false);
                                        }}>
                                    <HomeIcon fontSize={"large"}/>
                                </Button>
                            </Link>}
                        <SortMenu setSortType={setSortType} DisplayMode={DisplayMode}/>
                        <div
                            className={"hidden lg:flex flex-row w-[250px] lg:w-[350px] h-14 justify-self-end items-center justify-start gap-1"}>
                            <input placeholder={"Search name or text.."} value={st}
                                   onChange={e => setSt(e.target.value)}
                                   onKeyDown={e => {
                                       if (e.key === "Enter") {
                                           startsearch();
                                       }
                                   }} className={"h-8 bg-[#244939] border w-[200px] lg:w-[300px] border-[#51b78c] rounded-lg p-1"}/>
                            {st !== "" &&
                                <Button className={"flex w-2 h-full " + filtersOpen ? " bg-slate-400" : " bg-slate-600"}
                                        onClick={() => {
                                            setSt("");
                                            setSearchText("");
                                        }}>
                                    <BackspaceIcon/>
                                </Button>}
                        </div>
                    </div>
                    <div className={"flex flex-row w-1/3 h-12 lg:h-full items-center justify-center p-2"}>
                        <ToggleButtonGroup
                            color="primary"
                            className={"bg-[#244939] h-10"}
                            value={DisplayMode}
                            exclusive
                            onChange={handleDisplayChange}
                            aria-label="DisplayMode">
                            <ToggleButton value="All">All</ToggleButton>
                            <ToggleButton value="Units">Units</ToggleButton>
                            <ToggleButton value="Actions">Actions</ToggleButton>
                            <ToggleButton value="Mana">Mana</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div className={"flex flex-row w-1/3 h-full lg:h-full gap-2 items-center justify-end"}>
                        <Link to={"/cardsheet"}>
                            <Button color={dsOpen ? "primary" : "secondary"}
                                    className={"flex flex-row w-auto h-12 justify-center items-center "}>
                                <Print fontSize="large" />
                            </Button>
                        </Link>
                        <Button color={dsOpen ? "primary" : "secondary"}
                                className={"flex flex-row w-auto h-12 justify-center items-center "} onClick={() => {
                            setDsOpen(!dsOpen);
                        }}>
                            <DisplaySettingsIcon fontSize={"large"}/>
                            <p className={"hidden lg:flex uppercase"}>Filters</p>
                        </Button>
                        <Button color={dpOpen ? "primary" : "secondary"}
                                className={"flex flex-row w-auto h-12 justify-center items-center "} onClick={() => {
                            setDpOpen(!dpOpen);
                        }}>
                            <ViewModuleIcon fontSize={"large"}/> <p
                            className={"flex-row w-auto h-full justify-center items-center hidden lg:flex"}>Deck
                            Management</p>
                        </Button>
                    </div>
                </div>

                {dsOpen && <div
                    className={"absolute flex flex-col px-14 bg-[#0a0f13] z-50 h-auto w-full gap-2 lg:w-2/5 right-0 border-b lg:border-l p-4 " + (hideMenu ? "top-[75px] lg:top-[67px] " : "top-[145px] lg:top-[137px] ") + bordercolor}>
                    <div className={"flex flex-row w-full h-14 items-center gap-1 justify-center"}>
                        {/* <div className={"flex flex-row items-center justify-center h-14 w-2/6"}>
                            <Checkbox checked={hideMenu} onChange={(e) => {
                                setHideMenu(e.currentTarget.checked)
                            }} color="success"/>Hide Top Menu
                        </div>*/}
                        <div className={"lg:hidden flex flex-row w-full h-14 items-center justify-start gap-1"}>
                            <input placeholder={"Search name or text.."} value={st}
                                   onChange={e => setSt(e.target.value)}
                                   onKeyDown={e => {
                                       if (e.key === "Enter") {
                                           startsearch();
                                       }
                                   }} className={"h-8 bg-[#244939] border border-[#51b78c] w-full rounded-lg p-1"}/>
                            {st !== "" &&
                                <Button className={"w-4 h-full " + filtersOpen ? " bg-slate-400" : " bg-slate-600"}
                                        onClick={() => {
                                            setSt("");
                                            setSearchText("");
                                        }}>
                                    <BackspaceIcon/>
                                </Button>}
                        </div>
                        <p className={"flex flex-row w-[100px] h-full justify-start items-center"}>Identity</p>
                        <Select
                            className={"bg-[#244939] border border-[#51b78c] h-8 w-full"}
                            id="Identity"
                            placeholder={IdentityFilter}
                            value={IdentityFilter}
                            onChange={(e) => {
                                setIdentityFilter(e.target.value)
                            }}
                        >
                            <MenuItem value={"Any"}>Any</MenuItem>
                            {identities.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className={"flex flex-row w-full h-14 items-center gap-1 justify-center"}>
                        <p className={"flex flex-row w-[100px] h-full justify-start items-center"}>Keywords</p>
                        <Select
                            className={"bg-[#244939] border border-[#51b78c] w-5/6 h-8"}
                            id="Keywords"
                            multiple
                            value={keywordFilter}
                            onChange={handleKWChange}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {keywords.map((el) => (
                                <MenuItem key={el} value={el}>
                                    <Checkbox checked={keywordFilter.indexOf(el) > -1}/>
                                    <ListItemText primary={el}/>
                                </MenuItem>
                            ))}
                        </Select>
                        <Button className={"w-1/6 h-8"} onClick={() => {
                            setKeywordFilter([])
                        }}><BackspaceIcon/></Button>
                    </div>
                    <div className={"hidden flex-row w-full h-14 items-center gap-1 justify-center"}>
                        <p className={"flex flex-row w-[100px] h-full justify-start items-center"}>Edition</p><Select
                            className={"bg-[#244939] border border-[#51b78c] h-8 w-full"}
                            id="Edition"
                            placeholder={edition}
                            value={edition}
                            onChange={(e) => {
                                setEdition(e.target.value)
                            }}
                        >
                            <MenuItem value={"Base Set"}>First Edition</MenuItem>
                            {/*editions.map((el: string) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                            ))*/}
                        </Select>
                    </div>
                    {!cardsOnly && session && isAdmin &&
                        <div className={"flex flex-row w-full px-4 h-10 items-center justify-between p-2"}>
                            {myDecks.length > 1 && <><Checkbox checked={deckfilterActive} onChange={(e) => {
                                setDeckfilterActive(e.currentTarget.checked)
                            }} color="success"/>Hide in deck2</>}
                        </div>}
                </div>}
            </div>
        </div>
    )
}