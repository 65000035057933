import React, {useEffect, useState} from "react";
import {useAppContext} from "../contexts/appContext";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import {AddCardRounded, AddComment, DeleteForever} from "@mui/icons-material";
import axios from "axios";
import {useNewsContext} from "../contexts/newsContext";

const labels = "block uppercase text-textprimary text-xs font-bold mb-2";
const subinput = "px-3 py-3 placeholder-gray-400 rounded text-sm shadow focus:outline-none focus:ring w-full bg-[#244939] border border-[#51b78c] text-white";

export default function PatchNoteEditor(props) {
    const {art, setEditing} = props;
    const [posterName, setPosterName] = useState("");
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");
    const [message, setMessage] = useState(null);
    const [rdate, setRdate] = useState("");
    const [description, setDescription] = useState("");
    const [features, setFeatures] = useState([]);
    const [issues, setIssues] = useState([]);
    const [fixes, setFixes] = useState([]);
    const [improvements, setImprovements] = useState([]);
    const {session, isAdmin} = useAppContext();


    useEffect(() => {
        ResetEdits(art);
    }, [art]);

    function ResetEdits(ar) {
        setPosterName(ar.posterName);
        setTitle(ar.title);
        setMessage(JSON.parse(ar.content));
        setType(ar.type);
    }

    useEffect(() => {
        if(message){
            setRdate(message.rdate);
            setDescription(message.description);
            setFeatures(message.features);
            setIssues(message.issues);
            setFixes(message.fixes);
            setImprovements(message.improvements);
        }
    }, [message]);

    function AddeLe(elements, setElements){
        const els = [];
        elements.forEach(el => els.push(el));
        els.push("New Line");
        setElements(els);
    }

    const MessageContent = () => JSON.stringify({rdate: rdate, description: description, features:features, improvements: improvements, fixes: fixes, issues: issues});


    async function SubmitEdit() {
        if(!session) return;

        try {
            const { error } = await supabase
                .from('newsfeed')
                .update({ title: title, content: MessageContent(), type: type, poster: posterName, edited_at: Date.now().toString() })
                .eq('id', art.id)
            if(error){

            } else {
                toast.success('Edited news article successfully.', {
                    duration: 4000,
                    position: 'bottom-right',
                });
                setEditing(false);
            }
        } catch (error) {
            toast.error('Failed to edit.', {
                duration: 4000,
                position: 'bottom-right',
            });
        }
    }

    return (
        <div className={"flex flex-col w-full h-screen p-4"}>
            <div className="flex flex-col w-full h-auto px-4">
                <div className="flex flex-col h-full break-words w-full mb-6 shadow-lg rounded-lg bg-base-200">
                    <div className="flex-auto w-full p-5 lg:p-10">
                        <label className="form-control w-full max-w-xl" htmlFor="type">
                            <select className="select select-bordered"
                                    value={type}
                                    onChange={(e) => {
                                        setType(e.currentTarget.value);
                                    }}
                                    style={{transition: "all .15s ease"}}
                            >
                                <option disabled>Article type</option>
                                <option>Announcements</option>
                                <option>Bug Fixes</option>
                                <option>Patch Notes</option>
                            </select>
                        </label>
                        <div className="relative w-full mb-3 mt-8">
                            <label className={labels} htmlFor="title">
                                Title
                            </label>
                            <input
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.currentTarget.value);
                                }}
                                type="text"
                                className={subinput}
                                placeholder="Article title"
                                style={{transition: "all .15s ease"}}
                            />
                        </div>
                        <div className="relative w-full mb-3 mt-8">
                            <label className={labels} htmlFor="title">
                                Date
                            </label>
                            <input
                                value={rdate}
                                onChange={(e) => {
                                    setRdate(e.currentTarget.value);
                                }}
                                type="datetime-local"
                                className={subinput}
                                placeholder="date"
                                style={{transition: "all .15s ease"}}
                            />
                        </div>
                        <div className="relative w-full mb-3 mt-8">
                            <label className={labels} htmlFor="description">
                                Description
                            </label>
                            <textarea
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.currentTarget.value);
                                }}
                                className={subinput}
                                placeholder="description"
                                style={{transition: "all .15s ease"}}
                            />
                        </div>

                        <TBList str={"Known Issues"} AddeLe={AddeLe} elements={issues} setElements={setIssues}/>
                        <TBList str={"Features"} AddeLe={AddeLe} elements={features} setElements={setFeatures}/>
                        <TBList str={"Improvements"} AddeLe={AddeLe} elements={improvements}
                                setElements={setImprovements}/>
                        <TBList str={"Fixes"} AddeLe={AddeLe} elements={fixes} setElements={setFixes}/>

                        {/*
                        <div className="relative w-full mb-3 mt-8">
                            <label className={labels} htmlFor="content">
                                Content
                            </label>
                            <textarea id={"content"} readOnly={true}
                                      className={"min-h-[200px] h-full w-full bg-[#244939] border border-[#51b78c] text-white"}
                                      defaultValue={JSON.stringify(message)}>

                            </textarea>
                        </div>
                        */}
                    </div>
                    <div className="flex w-full h-auto flex-row text-center my-4 gap-10 items-center justify-center">
                        <Button
                            className="bg-primary mx-4 text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1"
                            type="button"
                            style={{transition: "all .15s ease"}}
                            onClick={() => {
                                setEditing(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            className="bg-error text-white active:bg-error-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1"
                            type="button"
                            style={{transition: "all .15s ease"}}
                            onClick={() => {
                                ResetEdits(art);
                            }}
                        >
                            Reset Edits
                        </Button>
                        <Button
                            className="bg-primary text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1"
                            type="button"
                            style={{transition: "all .15s ease"}}
                            onClick={() => {
                                SubmitEdit();
                            }}
                        >
                            Save Article
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function TBList(props) {
    const {elements, setElements, AddeLe, str} = props;
    const [tempEls, setTempEls] = useState([]);

    useEffect(() => {
        setTempEls(elements);
    }, [elements]);

    function handleEdit(ind, newstr) {
        let els = [];
        elements.forEach((el, ib) => {
            if (ind === ib) els.push(newstr);
            else els.push(el)
        });
        setElements(els);
    }

    function handleDelete(ind) {
        let els = [];
        elements.forEach((el, id) => {
            if (id !== ind) els.push(el)
        });
        setElements(els);
    }

    return (
        <div className="relative w-full mb-3 mt-2 p-1 border border-border">
            <div className={"flex flex-row justify-between items-center"}>
                <label className={labels} htmlFor={str}>
                    {str}
                </label>
                <Button onClick={() => AddeLe(elements, setElements)}><AddComment/></Button>
            </div>
            <div className="relative w-full mb-3">
                {tempEls && tempEls.map((el, ind) => (
                    <ElementField el={el} ind={ind} key={ind} handleEdit={handleEdit} handleDelete={handleDelete}/>
                ))}
            </div>
        </div>
    )
}

function ElementField(props) {
    const {el, ind, handleEdit, handleDelete} = props;
    const [tempValue, setTempValue] = useState("");

    useEffect(() => {
        setTempValue(el);
    }, [el]);

    return (
        <div className={"flex flex-row h-10 w-full p-1"}>
            <input
                value={tempValue}
                onChange={(e) => setTempValue(e.currentTarget.value)}
                onBlur={(e) => {
                    handleEdit(ind, tempValue);
                }}
                type="text"
                className={subinput}
                placeholder="description"
                style={{transition: "all .15s ease"}}
            />
            <Button onClick={() => {
                handleDelete(ind);
            }}><DeleteForever/></Button>
        </div>
    )
}